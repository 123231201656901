/* BotonWsp.module.css */

.fixedbtn {
    position: fixed;
    bottom: 1.5rem;
    right: 1.5rem;
    z-index: 50;
  }
  
  .bgGreen {
    background-color: #10b981;
  }
  
  .textWhite {
    color: #ffffff;
  }
  
  .fontBold {
    font-weight: normal;
  }
  
  .padding {
    padding: 1rem;
  }
  
  .rounded {
    border-radius: 9999px;
  }
  
  .flex {
    display: flex;
  }
  
  .itemsCenter {
    align-items: center;
  }
  
  .justifyCenter {
    justify-content: center;
  }
  
  .text3xl {
    font-size: 2rem; /* 30px */
  }
  
  .marginLeft2 {
    color: #ffffff;
    font-size: 1.5rem;
    margin-left: 0.5rem; /* 8px */
  }
  
  .fixedbtn a {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #10b981;
    color: white;
    font-weight: bold;
    padding: 1rem;
    border-radius: 9999px;
    text-decoration: none;
  }
  
  .fixedbtn a:hover {
    opacity: 0.8;
  }
  
  .fixedbtn h2 {
    margin-left: 0.5rem; /* 8px */
  }
  